<template>
  <div class="container">
      <Title msg="Amigos"/>
      <friends />
  </div>
</template>
<script>
import friends from '@/components/student/friends/index.vue'
import Title from '@/views/student/constants/title.vue'

export default {
  name: 'dataStudent',
  components: {
    friends,
    Title
  },
}
</script>
