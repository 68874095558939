<template>
  <div>
    <!---  End Preload -->
    {{ this.loadingFriends }}
    <div v-if="loadingFriends">
      <loadingQuiz/>
    </div>

    <!-- start friend -->
    <div class="dPublicado"
      v-for="friend in friends"
      :key="friend.student_recipient"
     v-else>
      <div class="row justify-content-center ddBody">
        <div class="col-12 col-sm-8 col-md-8 col-lg-6 dFondoC">
          <div class="row justify-content-center">
            <div class="col-">
              <a href="">
                <div class="dCirculo">
                  <img
                    class="imgAvatar"
                    :src="appDomainImg + 'profile/5.' + friend.user.avatar"
                  />
                </div>
              </a>
            </div>
            <div class="col tDatos align-self-center">
              <span v-text="friend.user.name" style="color:black;"></span>
              <div hidden>
                <a href="#">Responder</a>
                <i class="fas fa-thumbs-up btn-like"></i>
              </div>
            </div>

            <div class="col-auto">
              <!--  button -->
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btnpuntos"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fas fa-ellipsis-v"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <button
                    class="dropdown-item"
                    type="button"
                    :value="friend.student_recipient"
                    v-on:click="viewProfile(friend.student_recipient)"
                  >
                    Ver perfil
                  </button>
                  <button
                    class="dropdown-item"
                    type="button"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    :value="friend.student_recipient"
                    v-on:click="versusEdit(friend.student_recipient)"
                  >
                    Versus
                  </button>
                  <hr />
                  <button
                    class="dropdown-item"
                    type="button"
                    data-id="comment.id"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Versus</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <form
              ref="formRound"
              class="fromCreatorVersus"
              v-show="fromCreatorVersus"
            >
              <div class="form-group">
                <select
                  class="form-control"
                  v-model="course"
                  :disabled="!courses[0].id || loadingCourses"
                  name="course"
                >
                  <option
                    v-for="course in courses"
                    :key="course.slug"
                    :value="course.slug"
                    v-text="course.name"
                  ></option>
                </select>
              </div>

              <div class="form-group">
                <select
                  class="form-control"
                  v-model="themas"
                  :disabled="!themas[0].id || loadingCourses"
                  name="themas"
                >
                  <option
                    v-for="team in themas"
                    :key="team.id"
                    :value="team.id"
                    v-text="team.name"
                  ></option>
                </select>
              </div>
              <div class="form-group" hidden>
                <select
                  class="form-control"
                  v-model="amountQuestions"
                  :disabled="!themas[0].id || loadingCourses"
                  name="amount-questions"
                >
                  <option value="0" selected>Numero de preguntas</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
            </form>
            <div class="nameFriend" v-show="waitingFriend">
              <p>Esperando...</p>
            </div>
          </div>
          <div class="modal-footer" v-show="btnVersus">
            <div class="btnCreatorVersus">
              <button
                type="button"
                class="btn btn-secondary"
                :disabled="loadingCourses"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                v-on:click="sendVersus"
                :disabled="!themas[0].id || loadingCourses"
              >
                Comenzar
              </button>
            </div>
          </div>
          <div
            class="modal-footer"
            v-show="btnCancelVersus"
            v-on:click="cancelVersus"
          >
            <div class="btnAcept">
              <button
                type="button"
                class="btn btn-primary"
                data-dismiss="modal"
              >
                AceptarX
              </button>
            </div>
            <div class="btnCancelVersus">
              <button
                type="button"
                class="btn btn-primary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from 'axios';
import loadingQuiz from "@/views/student/constants/loading/friends.vue";

export default {
  components: {
        loadingQuiz
    },
  data() {
    return {
      friend: "",
      friends: "",
      course: "1",
      /*
      courses: [{
        id: 0,
        name: 'Curso',
        slug: 'slug'
      }],
      */
      team: "0",
      /*
      teams: [
        {
          id: 0,
          name: "Tema",
        },
      ],
      */
      amountQuestions: "10",
      btnCancelVersus: false,
      waitingFriend: false,
      btnVersus: true,
      fromCreatorVersus: true,
      loadingFriends: true,
    };
  },
  async created() {
    await this.$store.dispatch('setBlock')
    await this.$store.dispatch('setCourses')
    this.getFriends()
    this.course = this.courses[0].slug
  },
  methods: {
    loadingData() {
        this.loadingCourses = true;
    },
    loaded() {
        this.loadingCourses = false;
    },
    getVersusFriend(){

    },
    viewProfile(student_recipient){
        this.friend = student_recipient
    },
    versusEdit(student_recipient){
        this.friend = student_recipient
    },
    getFriends(){
        let self = this;
        const url = this.appDomainApi1 + "getfriends"
        axios
            .get(url,)
            .then(function(response) {
                if (response.data.friends.length) {
                    self.friends = response.data.friends
                    self.loadingFriends = false
                } else {
                    self.friend = [{
                        id: 0,
                        student_recipient: 'Sin amigo.',                                
                    }]
                    self.friend = self.friend[0].student_recipient
                }
            })
            .catch((error) => {
              console.log(error);
            });
    },
    sendVersus() {                              
        this.btnCancelVersus = true
        this.waitingFriend = true   
        this.btnVersus = false  
        this.fromCreatorVersus = false    

        if (this.course && this.team && parseInt(this.amountQuestions)) {
            let self = this;
            this.loadingData()
            const url = this.appDomainApi1 + ""
            axios
                .post(url, {
                    course: this.course,
                    team: this.team,
                    friend: this.friend,
                    //friend: this.friend,
                    amount_questions: this.amountQuestions
                })
                .then(function(response) {
                    this.friend = response.data.versusCreator
                })
                .catch(function(error) {
                    console.log(error)
                    self.loaded()
                });                        
        } else {
            alert("Debe especificar los datos de la ronda.")
        }
    },
    acceptVersus(){
        const url = this.appDomainApi1 + "versusAcceptVersus"
        axios.post(url, {
                course: "enviando algo",
            })
            .then(function(response) {
                console.log(response.data)
                window.location.href = response.data.url
            })
            .catch(function(error) {
                console.log(error)
            });
    },
    getVersus(){
        let self = this;
        const url = this.appDomainApi1 + "getVersus"
        axios.get(url)
            .then(function(response) {                       
                if (response.data.friends.length) {
                    self.friends = response.data.friends
                } else {
                    self.friend = [{
                        id: 0,
                        student_recipient: 'Sin amigo.',                                
                    }]
                    self.friend = self.friend[0].student_recipient
                }

            })
    },
    cancelVersus(){
        this.btnCancelVersus = false
        this.waitingFriend = false   
        this.btnVersus = true  
        this.fromCreatorVersus = true  
        this.loading = false
    },
    getCourses() {
      console.log('getCourses')
      const url = this.appDomainApi1 + "courses"
      axios.get(url)
          .then(function(response) {
              if (response.data.length) {
                  self.courses = response.data
                  self.course = self.courses[0].slug
              } else {
                  self.courses = [{
                      id: 0,
                      name: 'Sin Cursos Disponilbes.',
                      slug: 'Sin Cursos Disponilbes.'
                  }]
                  self.course = self.courses[0].slug
              }
          })
    },
    getTeams2() {
        let self = this;
        console.log(this.course)
        const url = this.appDomainApi1 + this.course + "/themes";
        axios
            .get(url,)
            .then(function(response) {
                if (response.data.length) {
                    self.themas = response.data
                    self.team = self.themas[0].id
                } else {
                    self.themas = [{
                        id: 0,
                        name: 'Sin temas disponilbes.'
                    }]
                    self.aux = false
                    self.team = self.themas[0].id
                }
            })
    },
    getTeams(){
      console.log("getTeams")
      const currentThema = this.team
      const themas = window.localStorage.getItem(currentThema)
      if (themas) {
        this.state.loadingThemas = false
      }
    },
    changeSelectedCourse(val) {
        if (val && val != "0") {
          this.$store.state.course = val
          this.$store.dispatch('setThemasVersus')
        }
    },     
  },
  watch: {
    course: function(val) {
        this.changeSelectedCourse(val)
        this.team = this.themas[0].id
    }
  },
  computed: {
    ...mapState([
      'block',
      'loadingCourses',
      'courses',
      'themas',
    ]),
  },
};
</script>

<style>
.dc1 {
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  /*background: green;*/
  margin: 5px;
}
</style>
